import React from 'react'
import './Hero.css';
import assets from '../assets/intro-bg.jpg';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className='hero'>
      <div className='mask'> 
        <img className='into-img' src={assets} alt='IntroImg'/>
      </div>
      <div className='content'>
         <p> Hi, I'M  A  Nishant Singh</p>
         <h1>React Developer.</h1>
        <div>
         <Link to='/project' className='btn'>Project</Link>
         <Link to='/contact' className='btn  btn-light'>Contact</Link>
        </div>
      </div>  
    </div>
  )
}

export default Hero;
