import React from 'react'
import './Footer.css';
import { FaFacebook, FaHome, FaLinkedin, FaMailBulk, FaPhone, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-container'>
         <div className='left'>
         <div className='location'>
               <FaHome 
                 size={20} 
                 style={{color:"#fff", 
                 marginRight:"2rem"}}
                 />
                <div>
                  <p>123 Sarswa Daurala Meerut.</p>
                  <p>UttarPardesh.</p>
                </div>
            </div>
          <div className='Phone'>
                <h4>
                  <FaPhone 
                      size={20} 
                     style={{color:"#fff", 
                     marginRight:"2rem"}}    
                  />   
                   +91-9756017535
                </h4>
          </div>
         <div className='email'>
               <h4>
                 <FaMailBulk 
                     size={20}  
                     style={{color:"#fff",
                      marginRight:"2rem"}}   
                     />   
                    erpnishantsingh@gmail.com
              </h4>
         </div>
       </div>
       <div className='right'>
        <h4>About the Company</h4>
        <p>I am Front-End Developer in the Nexgen iots   Leraning and discussing new project and design challenges</p>
        <div className='social'>
            <FaFacebook 
             size={30}  
             style={{color:"#fff",
             marginRight:"1rem"}}   
           />
           <FaTwitter 
             size={30}  
             style={{color:"#fff",
             marginRight:"1rem"}}   
           />
           <FaLinkedin 
             size={30}  
             style={{color:"#fff",
             marginRight:"1rem"}}   
           /> 
        </div>
        </div>
     </div>
   </div>
  )
}

export default Footer
