import pro1 from '../assets/project1.png';
import pro2 from '../assets/project2.png';
import pro3 from '../assets/project3.png';

const ProjectCardData =[
    {
        imgsrc: pro1,
        title:"Ecommerce Website Design",
        text:"Thriving Facbook community and top-ranked podcast,Amy inspires a grounded,tangible and self-affirming sense of 'wow! tangible can do this' for over 100,000 online entrepreneurs. Best-selling marketing courses'",
        view:"https://www.youtube.com/watch?v=P8YuWEkTeuE"
    },
    {
        imgsrc: pro2,
        title:"LMS/Course Website Design",
        text:"PodcastFacbook community and top-ranked podcast,Amy inspires a grounded,tangible and self-affirming sense of 'wow! tangible can do this' for over 100,000 online entrepreneurs. Best-selling marketing courses'",
        view:"https://www.youtube.com/watch?v=37KohMnlP7Q&list=PL9bD98LkBR7O4_SVeN8IXLQRjrGontmbT&pp=iAQB"
    },
    {
        imgsrc: pro3,
        title:"Rymo Ecomerce Design",
        text:" Top rankedFacbook community and top-ranked podcast,Amy inspires a grounded,tangible and self-affirming sense of 'wow! tangible can do this' for over 100,000 online entrepreneurs. Best-selling marketing courses'",
        view:"https://www.youtube.com/watch?v=P8YuWEkTeuE"
    },
   
 ];
 
 export default ProjectCardData;