import React from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import HeroImg from '../Components/HeroImg';
import Card from '../Components/Card';
import Work from '../Components/Work';
const Project = (props) => {
  return (
    <div>
      <Navbar/>
      <HeroImg heading="PROJECTS." text="Some of my most recent  works"/>
      <Work/>
      <Card/>
      <Footer/>
    </div>
  )
}

export default Project
