import React from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import HeroImg from '../Components/HeroImg'
import AboutContent from '../Components/AboutContent'


const About = (props) => {
  return (
    <div>
      <Navbar/>
      <HeroImg heading="ABOUT." text="Im a friendly Front-End Developer."/>
      <AboutContent/>
      <Footer/>
    </div>
  )
}

export default About